img {
  width: 200px;
}

.col-height {
  height: 300px;
}

.button {
  margin-left: 50px;
}

.button-col {
  position: relative;

  bottom: 0;
}

.survey-list-card {
  border: 0px solid black;
  background-color: #fff;
  height: 200px;
  width: 200px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 15px;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
