.svc-tab-designer .sd-title {
  display: flex;
}
.sd-title {
  display: block;
  font-family: $font-family;
  font-style: normal;
  color: $foreground;
  flex-direction: row;
  white-space: normal;
  word-break: break-word;
}

.sd-title.sd-container-modern__title {
  display: flex;
  padding: calcSize(2);
  border-bottom: 2px solid $primary;
  overflow: auto;
  font-family: $font-family;
  gap: calcSize(4);
  .sd-logo__image {
    margin-top: calcSize(1);
  }
}

.sd-header__text {
  flex-grow: 1;
  h3 {
    padding-bottom: calcSize(1);
    font-size: calcSize(4);
    font-weight: 700;
    line-height: calcSize(5);
    color: $primary;
  }
  h5 {
    font-size: calcSize(2);
    font-weight: 400;
    line-height: calcSize(3);
  }
}
//todo: add ability to customize class sv-title-actions in library
.sd-title {
  .sv-title-actions {
    width: 100%;
    justify-content: space-between;
  }
  .sd-action-bar {
    margin: calcSize(-1) 0;
    margin-right: calcSize(-3);
  }
}
.sd-root-modern:not(.svc-tab-designer) {
  .sd-container-modern__title {
    background-color: $background;
    box-shadow: 0px 2px 0px #19b394;
    .sd-header__text h3 {
      margin: 0;
    }
    .sd-description {
      margin: 0;
      margin-top: 8px;
    }
  }
}
