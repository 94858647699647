.pos {
  position: relative;
  bottom: 0px;
}

.linkHeading {
  font-size: 1.25rem;
}

.linkBackground {
  background: rgb(48, 67, 79);
  padding: 10px;
}

.linksBackground {
  background: #1d2849;
  bottom: 0px;
}
.links a {
  font-size: 15px;
  color: white;
}

.links a:hover {
  color: lightblue;
}
