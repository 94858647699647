.background-section {
  border: 0px solid black;
  border-radius: 15px;
  min-width: 350px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2) inset;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollcard {
  display: inline-block;
}

.title {
  white-space: normal;
}
