@import "../variables.scss";

.svc-rating-question-controls {
  position: absolute;
  bottom: calcSize(10);
  left: calcSize(5);
}

.svc-question__content--selected-no-border {
  .svc-rating-question-controls.svc-item-value-controls {
    display: flex;
  }
}

.svc-question__content--selected {
  .svc-rating-question-controls.svc-item-value-controls {
    display: flex;
  }
}

.svc-question__content {
  .sd-rating {
    margin-left: calcSize(9);
    width: calc(100% - 9 * #{$base-unit});
  }
  .svc-rating-question-controls {
    display: flex;
    width: calcSize(8);
  }
}
