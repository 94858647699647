#creatorElement {
  /* SurveyJS Creator V2 */
  --primary-bg-color: #ffffff;
  --secondary-bg-color: #ffffff;
}

.section {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px !important;
}

.my-modal {
  width: 90vw !important ;
  max-width: 90vw !important ;
}

.subsetting-div {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-err{
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px !important;
  border: 1px solid red;
}

.error-msg {
  color: red;
  font-size: 16px;
  margin-top: 5px;
}