.bg-yellow {
    background-color: #F0C271;
}
.bg-red {
    background-color: #DB6046;
}

.bg-grey {
    background-color: #4B697B;
}

.toDo {
    background-color: rgb(48, 67, 79);
}

.valueName{
    margin-bottom: 25px;
    display: inline-block
}

.valueRow{
    justify-content: center;
    display: flex;
    margin-top: 40px;
}

.customMark{
    height: 4px;
    border-radius: 9px;
    padding: .2em;
    background-color: #fcf8e3;
    top: 5px;
}

.moralValueName{
    margin-bottom: 0px;
}


@media (max-width: 768px){
    .moralImage{
        padding: 0;
    }

    .moralImage img {
        max-height: 50px;
    }
    .moralValueName{
        margin-top: 10px;
        margin-bottom: 15px;
    }
    
}

@media  (max-width: 480px){
    .welcomeHeading{
        font-size:  20px;
    }

  
    .moralImage img {
        max-height: 40px;
    }
}


@media  (max-width: 300px){
  
    .moralImage img {
        max-height: 30px;
    }
    .moralValueName{
        margin-bottom: 15px;
        margin-top: 25px;
    }
}