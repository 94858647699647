.about {
  height: 100vh;
  background-size: cover;
}

.outer-container {
  display: none;
}

.aboutUs {
  /* font-weight: 600; */
  /* font-size: 40px; */
  text-align: center;
  margin-top: 50px;
}

.aboutArrow {
  position: absolute;
  border-radius: 4px;
  bottom: 40%;
  background: white;
  left: 45%;
  font-size: 20px;
  padding: 15px;
  cursor: pointer;
  color: rgb(48, 67, 79);
  /* border: 5px solid white; */
}

.carousel .slide {
  background: linear-gradient(to top, transparent, rgb(48, 67, 79) 129%),
    linear-gradient(to bottom, transparent, rgb(48, 67, 79) 129%),
    linear-gradient(to top, transparent, rgb(48, 67, 79) 129%),
    linear-gradient(to bottom, transparent, rgb(48, 67, 79) 129%) !important;
  background-size: 50% 50% !important;
  background-position: 0px 0%, 0px 100%, 100% 0%, 100% 100% !important;
  background-repeat: no-repeat !important;
  padding: 30px;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: rgb(48, 67, 79);
  text-align: justify !important;
  border-radius: 8px;
}
.carousel-root {
  padding: 30px !important;
}
.aboutArrow:hover {
  text-decoration: none;
  color: rgb(48, 67, 79);
}

.teamImage {
  border-radius: 0px;
  height: 150px;
  max-width: 90%;
  /* -webkit-filter: grayscale(100%); 
  filter: grayscale(100%); */
}

.mission p:first-child {
  font-size: 40px;
  text-align: center;
}

.mission {
  padding: 15px;
}

.teamP {
  font-weight: 600;
  font-size: 24px;
}
.teamCol {
  text-align: center;
  padding: 10px;
  margin: 0 auto;
  cursor: pointer;
}

.team-card {
  border-radius: 15px !important;
  width: 18rem;
  transition: box-shadow 0.3s;
}

.team-card:hover {
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.2);
}

.team-img {
  width: 18rem;
  height: 18rem;
  object-fit: cover;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.team {
  /* background:#dbe0f0; */
  /* background: #f2f2f2; */
  /* background: #d9d9d9; */
  text-align: center;
  padding: 10px;
}
.divider {
  border: none;
  height: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  /* border-bottom: 1px solid #888886; */
  box-shadow: 0 20px 10px -20px #888886;
  margin: -50px auto 10px;
}

.teamCol:hover .teamText {
  display: block;
}

.teamCol .teamText {
  display: none;
}

.teamProfile {
  margin: auto;

  padding: 40px;
  background: #d9d9d9;
}

.teamProfileButton {
  background-color: rgb(48, 67, 79) !important;
  outline: none !important;
}

.teamProfileButton:active {
  box-shadow: none !important;
}

.teamProfile .teamImage {
  box-shadow: 6px 3px #888886;
  box-shadow: grey;
  border: #888886;
}

.teamProfile .teamRole {
  font-weight: 500;
  font-size: 24px;
}
@media (min-width: 768px) {
  .teamCol {
    max-width: 20%;
  }
  .adv {
    max-width: 30%;
  }
}

@media (min-width: 992px) {
  .teamImage {
    height: 250px;
  }
}
