.values {
  font-size: 20px;
  vertical-align: top;
  font-weight: 600;
  padding: 10px;
}

.Welcome {
  height: 75vh;
  /* padding: 30px; */
  font-size: 20px;
  font-weight: 500;
}

.Welcome1 {
  /* height: 75vh; */
  padding: 30px;
  font-size: 20px;
  font-weight: 500;
}
.valuesRow {
  padding: 10px;
}

.authPanel {
  border-radius: 30px !important;
}

.bigbtn1 {
  width: 300px;
  height: 80px;
  border-radius: 15px;
  background: rgb(44, 145, 44) !important;
  border: 0px solid green !important;
  color: white !important;
  box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.2);
  font-size: 1.5rem;
}

.p1 {
  font-size: 1.5rem;
  cursor: pointer;
  text-decoration: underline;
}

.bigbtn2 {
  width: 300px;
  height: 80px;
  border-radius: 15px;
  background: rgb(44, 145, 44) !important;
  border: 0px solid green !important;
  color: black !important;
  box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.2);
  font-size: 1.5rem;
}

.welcomeText p {
  padding: 15px;
  background: white;
}

.goalsHeading {
  font-size: 40px;
  color: #fa6b70;
  font-weight: 700;
}

.welcomeText {
  font-size: 25px;
  font-weight: 700;
  padding: 55px 0;
}

.welcomeText1 {
  font-size: 20px;
  font-weight: 500;
  padding: 30px 0;
}

.welcomeText1 p {
  padding: 15px;
  background: white;
}

/* .Login {
    display: none;
} */

.goalsImg {
  /* max-width: 100%;
  max-height: 300px; */
  width: 80%;
}

.goalsImgText {
  color: white;
  font-size: 18px;
}

.goalCard {
  border-radius: 7px;
  padding: 30px;
  font-size: 18px;
  font-weight: 600;
  min-height: 315px;
  background: white;
  cursor: pointer;
}

.goals {
  padding: 20px;
  background: rgb(48, 67, 79);
  position: relative;
}

.homeImg {
  display: none;
}
.goalsRow {
  /* background: #1d2849; */
}

.goalCardText {
  padding: 10px;
}

.goalText {
  text-align: justify;
  font-size: 18px;
  /* background: #1d2849; */
  padding: 20px 30px 30px 20px;
}

.goalCard:hover {
  -webkit-box-shadow: 0 0 10px #fff;
  box-shadow: 0 0 10px #fff;
}

.joinUs {
  font-size: 16px;
  padding: 30px;
  font-weight: 500;
  margin-top: 20px;
}

.homeImg {
  height: 100vh;
  /* background-image: url('./man_cross_fingers.jpg'); */
  background: #f7eedd;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
}

.KnowMore {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.knowArrow {
  background-color: white;
  color: rgb(48, 67, 79);
  text-decoration: none;
}
.knowArrow:hover {
  background-color: white;
  color: rgb(48, 67, 79);
  text-decoration: none;
}

.left-border {
  border-left: 1px solid grey;
}

.verticalDivider {
  padding: 10px 0;
}
@media (max-width: 600px) {
  .welcomeText {
    font-size: 18px;
    font-weight: 600;
    padding: 30px 0;
  }

  .Welcome {
    font-size: 18px;
    font-weight: 500;
  }
}

@media (max-width: 768px) {
  .verticalDivider {
    display: none;
  }
}

@media (max-width: 300px) {
  .Welcome1 {
    padding: 10px 0;
  }
}
