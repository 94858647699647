.sd-boolean {
  display: flex;
  width: max-content;
  position: relative;
  padding: calcSize(0.5);
  background: $background-for-editors;
  box-shadow: inset 0px 1px 2px $shadow-inner;
  border-radius: calcSize(12.5);
}
.sd-boolean__thumb,
.sd-boolean__label {
  display: block;
  color: $foreground-light;
  font-family: $font-family;
  font-size: calcSize(2);
  line-height: calcSize(3);
  padding: calcSize(1) calcSize(3);
}
.sd-boolean__switch {
  display: flex;
  padding: calcSize(0.5);
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100%);
  height: calc(100%);
  box-sizing: border-box;
  border-radius: calcSize(12.5);
}
.sd-boolean__control:focus ~ .sd-boolean__switch {
  box-shadow: inset 0 0 0 2px $primary;
}
.sd-boolean--indeterminate .sd-boolean__thumb {
  display: none;
}
.sd-boolean__thumb {
  background: $background;
  box-shadow: 0px 1px 2px $shadow-inner;
  border-radius: calcSize(12.5);
  transition-duration: 0.2s;
  transition-property: margin-left, transform;
  transition-timing-function: linear;
  color: $primary;
  font-weight: 600;
}
.sd-boolean--checked .sd-boolean__thumb {
  margin-left: 100%;
  transform: translateX(-100%);
}
.sd-boolean--disabled {
  .sd-boolean__thumb,
  .sd-boolean__label {
    color: $foreground-disabled-for-editors;
  }
}
.svc-question__content {
  .sd-boolean:not(.sd-boolean--checked):not(.sd-boolean--indeterminate)
    .sd-boolean__label:nth-of-type(1),
  .sd-boolean--checked .sd-boolean__label:nth-of-type(2) {
    color: $primary;
    font-weight: 600;
    background: $background;
    box-shadow: 0px 1px 2px $shadow-inner;
    border-radius: calcSize(12.5);
  }
  .sd-boolean__switch {
    display: none;
  }
}
.sd-boolean--error {
  background-color: $red-light;
}