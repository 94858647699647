@import "../variables.scss";

.svc-question__content {
  .sd-table {
    td:empty,
    th:empty {
      width: initial;
    }
  }
}
.svc-matrix-cell {
  position: relative;
}

.sd-table thead tr th .svc-matrix-cell {
  padding: calcSize(1.5);
}

.svc-matrix-cell--selected {
  position: absolute;
  border: calcSize(0.25) solid $secondary;
  border-radius: calcSize(0.5);
  top: calcSize(0);
  left: calcSize(0);
  right: calcSize(0);
  bottom: calcSize(0);
  display: none;
  &.svc-visible {
    display: block;
  }
}

.svc-hovered > .svc-matrix-cell--selected:not(.svc-visible) {
  border: calcSize(0.25) solid $secondary-light;
  border-radius: calcSize(0.5);
  display: block;
}

.svc-matrix-cell__question-controls {
  display: none;
  position: absolute;
  top: calc(50% - 3 * #{$base-unit});
  left: calc(50% - 3 * #{$base-unit});
}

.svc-matrix-cell__question-controls-button {
  display: block;
  width: calcSize(6);
  height: calcSize(6);
  background: $background;
  border: 1px solid $border;
  box-sizing: border-box;
  border-radius: 50%;
  padding: calcSize(1.5);
  cursor: pointer;

  use {
    fill: $foreground-light;
  }
}

.svc-matrix-cell:hover .svc-matrix-cell__question-controls {
  display: block;
}

.svc-question__content--selected-no-border {
  padding: 0;
  width: calc(100% - 25 * #{$base-unit});
  min-width: calcSize(70);
  box-shadow: none;
}

.svc-matrix-cell__popup {
  .sv-popup__container {
    background-color: $background;
  }
  .sv-popup_shadow.sv-popup_shadow {
    filter: unset;
  }
}
