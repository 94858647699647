.sv-page__title {
  font-weight: 500 !important;
  margin-bottom: 0.5em;
  margin-top: 1em;
}
.surveyTitle {
  margin-left: 5%;
}

.sv-body > span:first-child {
  margin-left: 5%;
}
