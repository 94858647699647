@import "../variables.scss";

.svc-image-item-value-wrapper {
  display: inline-block;
  position: relative;
}

.svc-image-item-value--new {
  display: none;
  height: max-content;
  .sd-imagepicker__image {
    box-sizing: border-box;
    border: 1px dashed $border;
    border-radius: 4px;
  }
}

.svc-question__content--selected .svc-image-item-value--new {
  display: inline-block;
}

.svc-image-item-value-controls {
  position: absolute;
  top: calcSize(2.5);
  right: calcSize(1.5);
}

.svc-image-item-value--new .svc-image-item-value-controls {
  top: calc(50% - 3 * #{$base-unit});
  left: calc(50% - 3 * #{$base-unit});
}

.svc-image-item-value-controls {
  display: flex;
}

.svc-image-item-value-controls__button {
  display: block;
  background-color: $background;
  border-radius: 50%;
  width: calcSize(6);
  height: calcSize(6);
  cursor: pointer;

  &:not(:last-child) {
    margin-right: calcSize(1);
  }

  .sv-svg-icon {
    margin-top: calc(50% - 1.5 * #{$base-unit});
    margin-left: calc(50% - 1.5 * #{$base-unit});
  }

  use {
    fill: $foreground-light;
  }
}

.svc-image-item-value--new .svc-image-item-value-controls__button {
  background-color: transparent;
}

.svc-image-item-value-controls__choose-file:hover {
  // background-color: $primary-light;
  use {
    fill: $primary;
  }
}

.svc-image-item-value-controls__remove:hover {
  // background-color: $red-light;
  use {
    fill: $red;
  }
}

.svc-image-item-value-controls__add {
  use {
    fill: $primary;
  }
}

.svc-image-item-value-controls__add:hover {
  background-color: $primary-light;
}

.svc-choose-file-input {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.svc-image-item-value__item {
  .sd-imagepicker__item.sd-imagepicker__item {
    width: 100%;
  }
  .sd-imagepicker__image-container {
    border: 1px dashed $border;
    border-radius: 4px;
  }
}

.svc-question__content--selected .svc-image-item-value-wrapper:hover {
  .svc-image-item-value-controls__drag-area-indicator {
    display: block;
  }
}

.svc-image-item-value-controls__drag-area-indicator {
  cursor: move;
  position: absolute;
  top: calcSize(2.5);
  left: calcSize(0.5);
  display: none;
}

.svc-image-item-value-wrapper--ghost {
  .svc-image-item-value-wrapper__ghost {
    display: block;
  }
  .svc-image-item-value-wrapper__content {
    display: none;
  }
}

.svc-image-item-value-wrapper__ghost {
  display: none;
  background: $background-dim;
  width: 200px;
  height: 135px;
  margin-top: 8px;
}
