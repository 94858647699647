@import "../../variables.scss";

svc-tab-test {
  width: 100%;
  height: 100%;
  background: $background-dim;
}

.svc-test-tab__content .svc-plugin-tab__content {
  overflow-y: auto;
  
  .svc-preview__test-again {
    width: calcSize(33);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 72px;
  }
}

.svc-creator-tab__content--with-toolbar.svc-test-tab__content .svc-plugin-tab__content {
  height: calc(100% - calc(6 * #{$base-unit}));
}

.svc-test-tab__content-actions {
  position: relative;
  .sv-action-bar {
    padding: 0;
    height: calc(6 * #{$base-unit});
    background: $background;
    border-top: 1px solid $border;
    width: 100%;
    position: absolute;
    &.sv-action-bar--pages {
      left: 0;
      right: 0;
      justify-content: center;
    }
  }

  .svc-page-selector {
    max-width: 50%;

    .sv-action-bar-item {
      width: 100%;
    }

    .sv-action-bar-item__title {
      @include textEllipsis;

      display: inline-block;
    }
  }
}
