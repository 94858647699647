.survey-list {
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 2px;

  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.survey-list:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}

.survey-list:hover::after {
  opacity: 1;
}

.border {
  border: 1px solid black;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.one-line-button {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.btn-pos {
  background-color: white;
}

.row-height {
  min-height: 30px;
  max-height: 50px;
}
