.sd-paneldynamic {
    .sd-progress {
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        z-index: 2;
    }
    & > .sd-panel {
        padding-top: 1px;
        padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
        & + .sd-paneldynamic__remove-btn {
            margin-bottom: calc(0.5 * var(--sd-base-vertical-padding));
        }
    }
    & .sd-paneldynamic__panel-wrapper > .sd-panel > .sd-panel__header {
        padding-bottom: 0;
        &:after {
            display: none;
        }
        padding-top: calc(0.5 * var(--sd-base-vertical-padding) + #{$base-unit});
        & > .sd-panel__title {
            color: $foreground-light;
        }
    }
    & .sd-paneldynamic__panel-wrapper > .sd-panel {
        & > .sd-panel__header ~ .sd-panel__content {
            margin-top: 8px;
        }
    }
}
.sd-question__content:first-child > .sd-paneldynamic {
    & > .sd-paneldynamic__panel-wrapper > .sd-panel > .sd-panel__header {
        padding-top: var(--sd-base-vertical-padding);
    }
}

.sd-paneldynamic__separator {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    border-color: #eaeaea;
    background: #eaeaea;
    height: 1px;
    border: none;
}
.sd-paneldynamic__panel-wrapper {
    padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
}
.sd-paneldynamic__panel-wrapper:after {
    display: table;
    clear: both;
    content: " ";
}
.sd-paneldynamic__panel-wrapper--in-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.sd-paneldynamic__btn {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background: $background;
    font-family: $font-family;
    font-weight: 600;
    font-style: normal;
    font-size: calcSize(2);
    line-height: calcSize(3);
    padding: calcSize(1) calcSize(3);
    cursor: pointer;
    border-radius: calcSize(12.5);
}
.sd-paneldynamic__remove-btn {
    float: right;
    margin-top: calc(0.5 * var(--sd-base-vertical-padding));
    margin-right: calcSize(-3);
}
.sd-paneldynamic__footer {
    clear: both;
    .sd-paneldynamic__prev-btn,
    .sd-paneldynamic__next-btn {
        display: block;
        svg {
            width: calcSize(2);
            height: calcSize(2);
        }
    }
    .sd-paneldynamic__add-btn,
    .sd-paneldynamic__progress-text,
    .sd-paneldynamic__progress--bottom {
        display: initial;
    }
}
.sd-paneldynamic__buttons-container {
    display: flex;
    align-items: center;
    padding: calc(var(--sd-base-vertical-padding) - #{$base-unit}) 0;
}
.sd-paneldynamic__add-btn {
    display: none;
    margin-left: calcSize(-3);
}
.sd-paneldynamic__progress-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: calcSize(-1);
}
.sd-paneldynamic__progress-text {
    display: none;
    color: $foreground-light;
    margin: 0 calcSize(2);
}
.sd-paneldynamic__progress--bottom {
    display: none;
}
.sd-paneldynamic__prev-btn,
.sd-paneldynamic__next-btn {
    display: none;
    width: 16px;
    height: 16px;
    .sv-svg-icon {
        display: block;
    }
}
.sd-paneldynamic__next-btn {
    transform: rotate(180deg);
}
.sd-paneldynamic__placeholder {
    .sd-paneldynamic__add-btn {
        display: initial;
        margin-left: 0;
    }
}
.sd-question--empty.sd-question--paneldynamic {
    & > .sd-question__content {
        padding-bottom: var(--sd-base-padding);
    }
}
.svc-question__content .sd-paneldynamic__panel-wrapper {
    padding: 0;
}
