.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.loginFormControl {
  height: auto;
  line-height: 1;
}

.form-control:focus {
  box-shadow: 0 0 0 0.1rem rgb(0 123 255 / 25%);
}

/* .chartjs-render-monitor{
  height: 50%;
  width: 100% !important;
} */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.sv-row {
  padding-bottom: 0px;
}

.tableBackground {
  background: white;
}

.backgroundBrain {
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  text-align: center;
  opacity: 0.4;
  width: 100%;
  position: absolute;
  background-position: center;
  background-attachment: fixed;
}

.pdfButton {
  background-color: #da5c45 !important;
  border-color: #da5c45 !important;
}

.pdfButton:focus {
  background-color: #fa6b70 !important;
  border-color: #da5c45 !important;
}

.pdfButton:visited {
  background-color: #fa6b70 !important;
  border-color: #da5c45 !important;
}

.pdfButton:hover {
  background-color: #fa6b70 !important;
  border-color: #da5c45 !important;
}

.pdfButton:active {
  background-color: #da5c45 !important;
  border-color: #da5c45 !important;
}

tr:nth-child(odd) {
  background-color: white;
}
/* table td { border-left: 1px solid #404040; } */

.surveyArrow {
  position: absolute;
  border-radius: 4px;
  bottom: 40%;
  background: black;
  left: 35%;
  font-size: 20px;
  padding: 15px;
  cursor: pointer;
  color: white;
  border: 5px solid white;
  border-style: double;
}

.surveyArrow2 {
  position: absolute;
  border-radius: 4px;
  bottom: 40%;
  background: black;
  left: 43%;
  font-size: 20px;
  padding: 15px;
  cursor: pointer;
  color: white;
  border: 5px solid white;
  border-style: double;
}

.downArrow {
  vertical-align: middle;
  margin-left: 4px;
}

.surveyArrow:hover {
  color: white;
  text-decoration: none;
}

.surveyArrow2:hover {
  color: white;
  text-decoration: none;
}

.sv-row:not(:last-child) {
  padding-bottom: 0px;
}

.container-fluid {
  padding: 0;
}

.row {
  margin: auto;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");

/* -- SVG CSS -- */

.sep1 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

.svg-separator {
  display: block;
  background: 0 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
  -webkit-transform: translateY(-100%) translateY(2px);
  transform: translateY(-100%) translateY(2px);
  width: 102%;
}
.svg-separator.bottom {
  top: auto;
  bottom: 0;
}

.sv-body__page,
.sv-body__footer {
  margin-top: 0px;
}

.sv-container-modern__title {
  padding-top: 2em;
}

.sep12 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

/* -- Row BG Color -- */

.green {
  background-color: #03a678;
}
.green2 {
  background-color: #1bbc9b;
}
.red {
  background-color: #d24d57;
}
.light-grey {
  background-color: #ecf0f1;
}
.light-grey2 {
  background-color: #ecf0f1;
}
.dark-blue {
  background-color: #22313f;
}
.grey-blue {
  background-color: #6c7a89;
}
.black {
  background-color: #333;
}
.stupid-blue {
  background-color: #19b5fe;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.newQuestions {
  text-align: left;
  max-width: 80%;
  margin: 4% auto;
  padding: 20px;
}

.thumb {
  height: 32px;
  line-height: 25px;
  width: 32px;
  text-align: center;
  background-color: #c5cdd3;
  color: black;
  top: -3px;
}

.element {
  justify-content: center;
  display: flex;
  align-items: center;
}

#todaoResults {
  margin-top: 5vh;
  color: white;
}
