.surveylist {
  border: 0px solid black;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 2px;

  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.surveylist:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}

.surveylist:hover::after {
  opacity: 1;
}

.chart {
  border: 0px solid black;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 2px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.chart:hover {
  -webkit-transform: scale(1.025, 1.025);
  transform: scale(1.025, 1.025);
}

.rowHeight {
  min-height: 30px;
  max-height: 70px;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.backgroundSection {
  border: 0px solid black;
  border-radius: 15px;
  min-width: 350px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2) inset;
}
