.action-button {
  background: #cee1ca !important;
  border: 3px solid green !important;
  color: black !important;
}

.switch-button {
  background: green !important;
}

.forgot {
  cursor: pointer;
}
