.item {
  display: flex;
  align-items: center;
}

.item {
  -webkit-animation: fadein 0.3s linear forwards;
  animation: fadein 0.3s linear forwards;
}
.item-fadeout {
  display: flex;
  align-items: center;
  -webkit-animation: fadeout 0.3s linear forwards;
  animation: fadeout 0.3s linear forwards;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
