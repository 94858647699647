.react-switch-handle {
  box-shadow: none !important;
}

.navbar {
  background: linear-gradient(to right, rgb(48, 67, 79), #808080);
  margin-bottom: 0;
  width: 100%;
  top: 0;
  padding: 0 10px;
  height: 60px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .navSwitch {
    align-items: baseline;
  }
  .nav-link-class {
    padding: 10px 10px 10px 0px !important;
    background: linear-gradient(to right, rgb(48, 67, 79), #808080);
  }
}

.nav-link-class {
  font-size: 14px !important;
  color: white !important;
  padding: 20px !important;
}

.nav-link-class:hover {
  color: #324167 !important;
  background: #dfe7ec;
}

.navbar-nav .active {
  color: black !important;
  background: #dfe7ec;
}
.navbar-toggler {
  border: none !important;
  outline: none !important;
}

.navbar-collapse {
  padding-left: 0px;
}

.changeFloat {
  float: right;
}

.dropdownNav a {
  color: white;
}

.dropdown-item {
  padding: 10px;
  text-decoration: none;
  display: block;
  color: black;
  font-size: 14px;
}
.dropdown-item :hover {
  text-decoration: none;
  color: black;
}

.dropdown-menu a:hover {
  background-color: #dfe7ec;
}

.dropdown-menu a:hover {
  text-decoration: none;
  color: black;
}

.surveyNav {
  text-decoration: none;
  display: inline-block;
}

.nav-link-class {
  display: inline-block;
}

.surveyNav :hover {
  text-decoration: none;
}

.navLinkClass {
  display: inline-block !important;
  font-size: 14px !important;

  padding: 20px !important;
}

.navLinkClass:hover {
  background: #dfe7ec;
}

.navLinkClass > a:first-child {
  padding: 0;
  color: white !important;
}

.navLinkClass > a:first-child:hover {
  color: #324167 !important;
  background: #dfe7ec;
}

.changeFloat .active {
  background: none;
  color: white !important;
}
.logoutClass:hover {
  background: #e6e6e6 !important;
  color: #324167 !important;
}

@media (max-width: 768px) {
  /* ReportBug Nav item */
  .logoutClass{
    background: linear-gradient(to right, rgb(48, 67, 79), #808080);
  }
  .logoutClass:hover {
    background: #dfe7ec !important;
    color: #324167 !important;
  }
  .changeFloat{
    background: linear-gradient(to right, rgb(48, 67, 79), #808080);
    width: 100%;
  }
}

