.accountInfo {
    margin: auto;
    text-align: left;
}

@media (min-width: 992px) {
    .accountInfo {
      max-width: 70%;
    }
  }
  